





















































import type { Link, Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

interface SustainabilityList {
  title: string
  htmltext: string
  icon: string
  items: {
    htmltext: string
    title?: string
    icon?: string
    pictures?: Picture[]
    link?: Link
  }[]
}

export default defineComponent({
  name: 'SustainabilityList',
  components: {},
  props: {
    content: {
      type: Object as PropType<SustainabilityList>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
